import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				ExpertPathway
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:title"} content={"ExpertPathway"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});